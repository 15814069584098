.fc-timegrid-event .fc-event-time {
    font-weight: bold;
}

.fc-direction-ltr .fc-timegrid-col-events {
    margin-top: 0px;
    margin-right: 2px;
    margin-bottom: 0px;
    margin-left: 2px;
}

.fc .fc-timegrid-now-indicator-line {
    opacity: .7;
    position: absolute;
    z-index: 4;
    left: 0;    
    right: 0;
    margin-top: -2px;    
    border-style: solid;    
    border-color: red;    
    border-color: var( — fc-now-indicator-color,red);    
    border-width: 5px 0 0;
}

/*
.fc {
    background-color: #111111;
    color: #6b6b6b;
}

.table-bordered th, .table-bordered td {
    border: 1px solid #6b6b6b;
}

.fc tr {
    color: #d8d8d8;
    border: 1px solid #222222;
}

.fc td {
    color: #d8d8d8;
    border: 1px solid #444444;
}

.fc th {
    color: #d8d8d8;
    border: 1px solid #777777;
}

:root {
    --fc-page-bg-color: black;
}
*/